import { Link } from "react-router-dom"
import Logo from "../../ressources/arb_black.svg"
import "./nda.scss"
function NDAPage() {

    return (
      <div className="night-wrapper-box">
        <img src={Logo} alt="" />
        <p>
        Vielen Dank für Ihr Interesse an der Nacht der Ausbildung bei ARBURG.
        Leider müssen wir Ihnen mitteilen, dass die Veranstaltung nicht stattfindet.
        </p>
        <p>
        Wir würden uns freuen Sie bei unseren ARBURG Infotagen am <b>27. & 28. Juni 2025</b> begrüßen zu dürfen.
        Falls Sie einen konkreten Ausbildungsberuf kennenlernen möchten, besteht ebenfalls jederzeit die Möglichkeit sich in unserem <Link to="/Jobboard?type=praktikum">Jobportal</Link> auf ein Schulpraktikum zu bewerben.
        </p>
      </div>
    )
  }
  
  export default NDAPage