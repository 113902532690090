import React, { useContext, useEffect, useState } from "react";
import {
  HashRouter as Router,
  // BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { UserContext } from ".";
import Login from "./pages/Backend/Login/Login";
import Register from "./pages/Backend/Register/Register";
import comp_CheckIn from "./pages/Backend/CheckIn/CheckIn";
import comp_DataDeleter from "./pages/Backend/DataDeleter/DataDeleter";
import comp_DataUploader from "./pages/Backend/DataUploader/DataUploader";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import { fb, auth } from "./firebase";
import "./bootstrap.scss";
import "./App.scss";

// Custom Components
import {

  comp_Home,
  comp_Ausbilder,
  comp_Ausbildung,
  comp_Ausbildung_Details,
  comp_Backend,
  comp_Bewerbung,
  comp_Dashboard,
  comp_FAQ,
  comp_Kontakt,
  comp_News,
  comp_Artikel,
  comp_Profile,
  comp_Projektgruppen,
  comp_Specials,
  comp_BackendAnmeldungen,
  comp_UeberUns,
  comp_Impressum,
  comp_Datenschutz,
  comp_ProjektOrganizer,
  comp_ProjektCreate,
  comp_ProjektEdit,
  comp_Linktree,
  comp_AzubiInfo,
  comp_AzubiInfoMonitor,
  comp_Infoordner,
  comp_NoMatchWartung,
  comp_Leitbild,
  comp_WartungsControl,
  comp_PWReset,
  comp_SocialMobile,
  comp_Veranstaltung,
  comp_Account,
  comp_Evaluation,
  comp_Jobboard,
  comp_studienpraktikamanager,
  comp_Praktikumpost,
  comp_Hs_List,
  comp_Jobboard_redirect,
  comp_JobboardAusbildung,
  comp_AusbildungPost,
  comp_ausbildungsmanager,
  comp_siaseite,
  comp_ndaPage
} from "./customComp";
import Backend from "./pages/Backend/Backend";
import Loading from "./components/Loading/Loading";
import QRgenerator from "./pages/Backend/QRgenerator/QRgenerator";
import NeueAzubis from "./pages/Static/NeueAzubis";
import Cookies from './components/Cookies/Cookies'

function signOut() {
  fb.firestore()
    .collection("users")
    .doc(auth.currentUser.uid)
    .update({ signedIn: false })
    .then(function () {
      console.log("logout");
      window.location.href = "/#/signin";
      auth.signOut();
    })
    .catch(function (error) {
      console.log("User not loged out", error);
    });
  return <Redirect to="/signin"></Redirect>;
}

const Routes = () => {
  const user = useContext(UserContext);

  return (
    <Router>
      <Switch>
        {/* PublicRoutes */}
        <Route path="/signin" component={Login} exact={true} />
        <Route path="/SocialMedia" component={comp_SocialMobile} exact={true} />
        <Route path="/pwreset" component={comp_PWReset} exact={true} />
        <Route path="/" component={comp_Home} exact={true} />
        <Route path="/Karriere" component={comp_Ausbildung} exact={true} />
        <Route path="/Kontakt" component={comp_Kontakt} exact={true} />
        <Route path="/Karriere/:id" component={comp_Ausbildung_Details} exact={true} />
        <Route path="/News" component={comp_News} exact={true} />
        <Route path="/News/:id" component={comp_Artikel} exact={true} />
        <Route path="/Bewerbung" component={comp_Bewerbung} exact={true} />
        <Route path="/faq" component={comp_FAQ} exact={true} />
        <Route path="/Ausbilder" component={comp_Ausbilder} exact={true} />
        <Route path="/Impressum" component={comp_Impressum} exact={true} />
        <Route path="/Datenschutz" component={comp_Datenschutz} exact={true} />
        <Route path="/Ueber_Uns" component={comp_UeberUns} exact={true} />
        <Route path="/Linktree" component={comp_Linktree} exact={true} />
        <Route path="/Leitbild" component={comp_Leitbild} exact={true} />
        <Route path="/Veranstaltung/:id" component={comp_Veranstaltung} exact={true} />
        <Route path="/Specials" component={comp_Specials} exact={true} />
        <Route path="/FuerUnsereNeuenRookies2021" component={NeueAzubis} exact={true} />
        <Route path="/jobboard" component={comp_JobboardAusbildung} exact={true} />
        <Route path="/jobboard/ausbildung/:id" component={comp_AusbildungPost} exact={true} />
        <Route path="/jobboard/hs" component={comp_Jobboard} exact={true} />
        <Route path="/jobboard/hs/:id" component={comp_Praktikumpost} exact={true} />
        <Route path="/sia-bag-for-good" component={comp_siaseite} exact={true} />
        <Route path="/nda" component={comp_ndaPage} exact={true} />
        
        {user !== null && (user.role === "1" || user.role === "2" || user.role === "3") ? (
          <Switch>
            {/* private Routes Infoordner */}
            <Route path='/VPLAN' component={() => { window.location.href = 'https://arburg.vplan.de/'; return null; }} />
            <Route path="/Infoordner" component={comp_Infoordner} exact={true} />
            <Route path="/signout" component={signOut} exact={true} />
            <Route path="/Account" component={comp_Account} exact={true} />
            <Route path="/Projektgruppen" component={comp_Projektgruppen} exact={true} />
            <Route path="/AzubiInfo" component={comp_AzubiInfo} exact={true} />
            <Route path="/AzubiInfoMonitor" component={comp_AzubiInfoMonitor} exact={true} />
            
            {user.role === "3" || user.role === "2" ?
              (
                <Switch>
                  <Route path="/Evaluation" component={comp_Evaluation} exact={true} />
                  <Route path="/studienpraktikamanager" component={comp_studienpraktikamanager} exact={true} />
                  <Route path="/ausbildungsmanager" component={comp_ausbildungsmanager} exact={true} />
                  {user.role === "2" ?

                  (<Switch>
                    {/* private Routes Admin */}
                    <Route path="/Backend" component={comp_Backend} exact={true} />
                    <Route path="/register" component={Register} exact={true} />
                    <Route path="/Dashboard" component={comp_Dashboard} exact={true} />
                    <Route path="/Profile" component={comp_Profile} exact={true} />
                    <Route path="/Wartung" component={comp_WartungsControl} exact={true} />
                    <Route path="/ProjektOrganizer" component={comp_ProjektOrganizer} exact={true} />
                    <Route path="/QRgenerator" component={QRgenerator} exact={true} />
                    <Route path="/ProjektCreate" component={comp_ProjektCreate} exact={true} />
                    <Route path="/ProjektEdit/:id" component={comp_ProjektEdit} exact={true} />
                    <Route path="/ProjektEdit" component={comp_ProjektEdit} exact={true} />
                    <Route path="/DataUploader" component={comp_DataUploader} />
                    <Route path="/DataDeleter" component={comp_DataDeleter} />
                    <Route path="/CheckIn" component={comp_CheckIn} exact={true} />
                    <Route path="/Anmeldungen" component={comp_BackendAnmeldungen} />
                    <Route path="/" component={comp_Home} exact={true} />
                    <Route path="/" render={() => <Redirect to="/" />} />
              </Switch>) :
              (<Switch>
                <Route path="/" component={comp_Home} exact={true} />
                <Route path="/" render={() => <Redirect to="/" />} />
              </Switch>)}
                </Switch>
              ) :
              (
                <Switch>
                  <Route path="/" component={comp_Home} exact={true} />
                  <Route path="/" render={() => <Redirect to="/" />} />
                </Switch>)
            }
              
              
              

          </Switch>
        ) : (

          <Switch>
            <Route path="/" component={comp_Home} exact={true} />
            <Route path="/" render={() => <Redirect to="/" />} />
          </Switch>
        
        )}

      </Switch>
    </Router >
  );
};

const Wartungsmodus = () => {
  return (
    <Router>
      {/* <Wartung/> */}
      <Switch>
        <Route path="/Wartung" component={comp_NoMatchWartung} exact={true} />
        <Route path="/signin" component={Login} exact={true} />
        <Route path="/pwreset" component={comp_PWReset} exact={true} />
        <Route path="/" render={() => <Redirect to="/Wartung" />} />
      </Switch>
    </Router>
  );
};

function App() {
  const { enableLinkTracking } = useMatomo();
  enableLinkTracking();
  const [trigger, settrigger] = useState(undefined);
  useEffect(() => {
    let ref = fb.firestore().collection("Wartung");

    let unsubscribe = ref.onSnapshot(onCollectionUpdate);

    return () => {
      unsubscribe();
    };
  }, []);

  const onCollectionUpdate = (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      // this.setState({ trigger: doc.data().trigger });
      settrigger(doc.data().trigger);
    });
  };

  const user = useContext(UserContext);
  <Routes />

  if (trigger === undefined) {
    return <div>{<Loading />}</div>;
  } else if (trigger === true) {
    if (user !== null) {
      <div className="App">
        <Backend />
      </div>;
    }

    return <div className="App">{<Wartungsmodus />} </div>;
  } else {
    // return <div className="App">{<PublicRoutes />}<Cookies/> </div>;
    if (decodeURIComponent(document.cookie).includes("statisticCookie")) {
      return <div className="App">{<Routes />}</div>;
    }
    else {
      return <div className="App">{<Routes />}{<Cookies />}</div>;
    }
  }
}
export default App;