import Homepage from "./pages/Homepage/Homepage";
import Navbar from "./components/Navbar/Navbar.jsx";
import News from "./pages/News/News";
import NewsDetails from './components/News/BlogEntries/NewsDetails/NewsDetails';
import OnePager from './pages/News/NewsOnePager/NewsOnepager.jsx';
import Projekt from "./pages/Projektgruppen/Projekt.jsx";
import Ausbildung from "./pages/Ausbildung/Ausbildung.jsx";
// import Ausbildungsberufedetails from "./components/Ausbildungsberufe/Ausbildungsberufedetails";
import ContentDetails from './components/Ausbildung/AusbildungAccordion/AccordionContent/ContentDetails/ContentDetails'
import Bewerbung from "./pages/Bewerbung/Bewerbung.jsx";
import Specials from "./pages/Specials/Specials.jsx";
import Ausbilder from "./pages/Ausbilder/Ausbilder";
// import AnmeldungElternabend from './pages/Anmeldungen/elternabend/AnmeldungElternabend';
import Footer from "./components/Footer/Footer";
import SocialMedia from "./components/SocialMedia/SocialMedia";
import UeberUns from "./pages/UeberUns/UeberUns";
import Backend from "./pages/Backend/Backend";
import Kontakt from "./pages/Kontakt/Kontakt";
import Anmeldungen from './pages/Backend/Anmeldungen/Anmeldungen'
import Veranstaltung from './components/Veranstaltungen/VeranstaltungDetails';
import FAQ from "./pages/FAQ/FAQ";
// import Slideshow from './components/Homepage/Slideshow/Slideshow';
import Dashboard from "./pages/Backend/Dashboard/Dashboard";
import Statistiken from "./pages/Backend/Statistiken/Statistiken";
import Profile from "./pages/Backend/Profile/Profile";
import ProjektOrganizer from "./pages/Backend/ProjektOrganizer/ProjektOrganizer";
import ProjektCreate from './pages/Backend/ProjektOrganizer/ProjektCreate/ProjektCreate'
import ProjektEdit from './pages/Backend/ProjektOrganizer/ProjektEdit/ProjektEdit'
import Datenbankverwaltung from "./pages/Backend/Datenbankverwaltung/Datenbankverwaltung";
import Tabellen from "./pages/Backend/Tabellen/Tabellen";
import Views from "./pages/Backend/Views/Views";
import Linktree from "./pages/Linktree/Linktree.jsx"
import { Redirect } from "react-router-dom";
import Datenschutz from './pages/Datenschutz/Datenschutz'
import Impressum from './pages/Impressum/Impressum';
import Wartung from './components/Wartung/Wartung';
import Infoordner from './pages/Infoordner/Infoordner.jsx';
// import LogoutButton from "./components/LogoutButton/LogoutButton";
import Leitbild from "./pages/Leitbild/Leitbild";
import WartungsControl from "./pages/Backend/Wartung/WartungsControl";
import PWReset from './pages/Backend/PWReset/PWReset';
import SocialMobile from "./components/SocialMedia/SocialMobile";
import Matomo from "./components/Matomo/Matomo";
import Account from "./pages/Account/Account";
import AzubiInfo from "./pages/AzubiInfo/AzubiInfo";
import AzubiInfoMonitor from "./pages/AzubiInfo/Monitor/AzubiInfoMonitor";
import Evaluation from "./pages/Evaluation/Evaluation";
import Jobboard from "./pages/Jobboard/Jobboard";
import StudienpraktikaManager from "./pages/StudienpraktikaManager/PraktikaManager/StudienpraktikaManager";
import PraktikumPost from "./components/Studienpraktika/PraktikaEntries/PraktikumPost";

import JobboardAusbildung from "./pages/JobboardAusbildung/JobboardAusbildung";
import AusbildungPost from "./components/JobboardAusbildung/JobboardEntries/AusbildungPost";
import AusbildungsManager from "./pages/AusbildungsManager/AusbildungsManager";
import NDAPage from "./pages/NDA/nda";
import SIASeite from "./pages/SIASeite/SIASeite.jsx";

export const comp_Home = () => (
  <div>
    <Navbar />
    <Homepage />
    <Footer />
    <Matomo title="Home" url={window.location.href}/>
  </div>
);

export const comp_Impressum = () =>(
  <div>
  <Navbar />
  <Impressum/>
  <Footer />
  <Matomo title="Impressum" url={window.location.href}/>
</div>
)
export const comp_Datenschutz = () =>(
  <div>
  <Navbar />
  <Datenschutz/>
  <Footer />
  <Matomo title="Datenschutz" url={window.location.href}/>
</div>
)

export const comp_Infoordner = () =>(
  <div>    
    <Navbar/>    
    {/* <LogoutButton/> */}
    <Infoordner/>
    <Footer/>
    <Matomo title="Infoordner" url={window.location.href}/>
  </div>
)

export const comp_Account = () =>(
  <div>    
    <Navbar/>    
    <Account/>
    <Footer/>
    <Matomo title="Account" url={window.location.href}/>
  </div>
)

export const comp_Ausbildung = () => (
  <div>
    <Navbar />
    <SocialMedia />
    <Ausbildung />
    <Footer />
    <Matomo title="Ausbildung" url={window.location.href}/>
  </div>
);

export const comp_Ausbildung_Details = () => (
  <div>
    <Navbar />
    <SocialMedia />
    <ContentDetails/>
    <Footer />
    <Matomo title="Ausbildung Details" url={window.location.href}/>
  </div>
);

export const comp_FAQ = () => (
  <div>
    <Navbar />
    <SocialMedia />
    <FAQ />
    <Footer />
    <Matomo title="FAQ" url={window.location.href}/>
  </div>
);

export const comp_News = () => (
  <div>
    <Navbar />
    <SocialMedia />
    <News />
    <Footer />
    <Matomo title="News" url={window.location.href}/>
  </div>
);
export const comp_Artikel = () => (
  <div>
    <Navbar />
    <SocialMedia />
    <NewsDetails/>
    {/* <News /> */}
    <Footer />
    <Matomo title="News Details" url={window.location.href}/>
  </div>
);

export const comp_Projektgruppen = () => (
  <div>
    <Navbar />
    <SocialMedia />
    <Projekt />
    <Footer />
    <Matomo title="Projektgruppen" url={window.location.href}/>
  </div>
);

export const comp_Bewerbung = () => (
  <div>
    <Navbar />
    <SocialMedia />
    <Bewerbung />
    <Footer />
    <Matomo title="Bewerbung" url={window.location.href}/>
  </div>
);
export const comp_Ausbilder = () => (
  <div>
    <Navbar />
    <SocialMedia />
    <Ausbilder />
    <Footer />
    <Matomo title="Ausbilder" url={window.location.href}/>
  </div>
);
export const comp_Specials = () => (
  <div>
    <Navbar />
    <Specials />
    {/* <SocialMedia/> */}
    <Footer />
    <Matomo title="Specials" url={window.location.href}/>
  </div>
);
export const comp_OnePager = () => (
  <div>
    <Navbar />
    <SocialMedia />
    <OnePager />
    <Footer />
    <Matomo title="OnePager" url={window.location.href}/>
  </div>
);

export const comp_Veranstaltung = () => (
  <div>
    <Navbar />
    <Veranstaltung />
    <Footer />
    <Matomo title="Veranstaltung" url={window.location.href}/>
  </div>
);


export const comp_Backend = () => (
  <div>
    <Backend />
  </div>
);
export const comp_BackendAnmeldungen = () => (
  <div>
    <Anmeldungen />
  </div>
);

export const comp_Dashboard = () => (
  <div>
    <Dashboard />
  </div>
);

export const comp_Datenbankverwaltung = () => (
  <div>
    <Datenbankverwaltung />
  </div>
);
export const comp_Profile = () => (
  <div>
    <Profile />
  </div>
);
export const comp_PWReset = () => (
  <div>
    <PWReset/>
  </div>
);
export const comp_WartungsControl = () => (
  <div>
    <WartungsControl />
  </div>
);

export const comp_ProjektOrganizer = () => (
  <div>
    <ProjektOrganizer />
  </div>
);
export const comp_ProjektCreate = () => (
  <div>
    <ProjektCreate />
  </div>
);
export const comp_ProjektEdit = () => (
  <div>
    <ProjektEdit />
  </div>
);
export const comp_Statistiken = () => (
  <div>
    <Statistiken />
  </div>
);
export const comp_Tabellen = () => (
  <div>
    <Tabellen />
  </div>
);
export const comp_Views = () => (
  <div>
    <Views />
  </div>
);

export const comp_UeberUns = () => (
  <div>
    <Navbar />
    <SocialMedia />
    <UeberUns />
    <Footer />
    <Matomo title="Über Uns" url={window.location.href}/>
  </div>
);

export const comp_AzubiInfo = () => (
  <div>
    <Navbar />
    <AzubiInfo />
    <Footer />
    <Matomo title="AzubiInformation" url={window.location.href}/>
  </div>
  );

  export const comp_AzubiInfoMonitor = () => (
    <div>
      <AzubiInfoMonitor />
      {/* <Matomo title="AzubiInformation" url={window.location.href}/> */}
    </div>
    );

export const comp_SocialMobile = () => (
  <div>
    <Navbar />
    {/* <SocialMedia /> */}
    <SocialMobile />
    <Footer />
    <Matomo title="Soziale Medien" url={window.location.href}/>
  </div>
); 

export const comp_Linktree = () => (
<div>
  <Linktree />
  {/* <Footer /> */}
  <Matomo title="Linktree" url={window.location.href}/>
</div>
);

export const comp_Kontakt = () => (
  <div>
    <Navbar />
    <SocialMedia />
    <Kontakt />
    <Footer />
    <Matomo title="Kontakt" url={window.location.href}/>
  </div>
);

export const comp_Leitbild = () => (
  <div>
    <Navbar />
    <SocialMedia />
    <Leitbild />
    <Footer />
    <Matomo title="Leitbild" url={window.location.href}/>
  </div>
);

export const comp_Evaluation = () => (
  <div>
    <Navbar />
    <Evaluation />
    <Footer />    
  </div>
)
export const comp_Jobboard = () => (
  <div>
    <Jobboard />
  </div>
)
export const comp_Jobboard_redirect = () => (
  <Redirect
  to={{
    pathname: "/Jobboard/hs"
  }}
/>
)

export const comp_JobboardAusbildung = () => (
  <div>
    <JobboardAusbildung />
  </div>
)

export const comp_Praktikumpost = () => (
  <div>
    <PraktikumPost />
  </div>
)
export const comp_AusbildungPost = () => (
  <div>
    <AusbildungPost />
  </div>
)

export const comp_studienpraktikamanager = () => (
  <div>
    <StudienpraktikaManager />
  </div>
)
export const comp_ausbildungsmanager = () => (
  <div>
    <AusbildungsManager />
  </div>
)

export const comp_siaseite = () => (
  <div>
    <SIASeite />
  </div>
)

export const comp_ndaPage = () => (
  <div>
    <NDAPage />
  </div> 
)

export const comp_NoMatch = () => (
  <div>
    <Redirect to="/" />
  </div>
);

export const comp_NoMatchWartung = () => (
  <div>
    <Wartung/>
    <Redirect to="/Wartung" />
  </div>
)
